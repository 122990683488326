import { BPH_SIKORA_ID, PHOSPHORUS_ID, POTASSIUM_ID } from './results';

export const jobStatuses = {
  PENDING: 0,
  FAILED: -1,
  SUCCESS: 1,
};

export const PHOSPHORUS_ISU = 'phosphorus_isu';
export const POTASSIUM_ISU = 'potassium_isu';
export const LIME_ISU_60 = 'lime_isu_60';
export const LIME_ISU_65 = 'lime_isu_65';
export const LIME_ISU_69 = 'lime_isu_69';
export const PHOSPHORUS_TRI_STATE = 'phosphorus_tri_state';
export const POTASSIUM_TRI_STATE = 'potassium_tri_state';
export const LIME_TRI_STATE_60 = 'lime_tri_state_60';
export const LIME_TRI_STATE_65 = 'lime_tri_state_65';
export const LIME_TRI_STATE_68 = 'lime_tri_state_68';
export const LIME_TRI_STATE_OH_60 = 'lime_tri_state_oh_60';
export const LIME_TRI_STATE_OH_65 = 'lime_tri_state_oh_65';
export const LIME_TRI_STATE_OH_68 = 'lime_tri_state_oh_68';
export const PHOSPHORUS_SDSU_MN_OLSEN = 'phosphorus_sdsu_mn_olsen';
export const PHOSPHORUS_SDSU_MN_BRAY = 'phosphorus_sdsu_mn_bray';
export const POTASSIUM_SDSU_MN = 'potassium_sdsu_mn';
export const LIME_SDSU = 'lime_sdsu';
export const SATURATION_BRAZIL = 'saturation_brazil';
export const PHOSPHORUS_BRAZIL = 'phosphorus_brazil';
export const POTASSIUM_BRAZIL = 'potassium_brazil';

export const DEFAULT = 'default';
export const JOHN_DEERE_RX = 'john_deere_rx';
export const CNH_RX = 'cnh_rx';
export const AG_LEADER_RX = 'ag_leader_rx';
export const RAVEN_VIPERPPRO_RX = 'raven_viper_pro_rx';
export const RAVEN_VIPER4_RX = 'raven_viper_4_rx';
export const RAVEN_ENVIZIOPRO_RX = 'raven_envizio_pro_rx';

export const INPUT_10_34_0_20_PERCENT_ID = 5;

const DEFAULT_P_INPUT_ID = 1;
const DEFAULT_PH_INPUT_ID = 2;
const DEFAULT_K_INPUT_ID = 3;
export const CUSTOM_CCE_ISU = 1003;
export const CUSTOM_RNV_ENM_ISU = 1004;
export const CUSTOM_CCE_SDSU = 1005;
export const CUSTOM_RNV_SDSU = 1006;
export const CUSTOM_RNV_INDIANA = 1007;
export const CUSTOM_NV_MICHIGAN = 1008;
export const CUSTOM_ENP_OHIO = 1009;
export const CUSTOM_RNP_BRAZIL = 1010;
export const SUPER_FOSFATO_SIMPLES = 1011;

export const inputNames = {
  [CUSTOM_CCE_ISU]: 'calciumCarbonateEquivalent',
  [CUSTOM_RNV_ENM_ISU]: 'relativeNeutralization',
  [CUSTOM_CCE_SDSU]: 'calciumCarbonateEquivalent',
  [CUSTOM_RNV_SDSU]: 'relativeNeutralization',
  [CUSTOM_RNV_INDIANA]: 'relativeNeutralization',
  [CUSTOM_NV_MICHIGAN]: 'neutralization',
  [CUSTOM_ENP_OHIO]: 'neutralization',
  [CUSTOM_RNP_BRAZIL]: 'relativeNeutralization',
};

export const DEFAULT_RX_VALUES_US = {
  [PHOSPHORUS_ID]: {
    crop: 'corn',
    type: 'soil_test',
    timing: 'preplant',
    input_id: DEFAULT_P_INPUT_ID,
    formula_name: PHOSPHORUS_ISU,
    minimum_rate: 50,
    expected_yield: 180,
  },
  [POTASSIUM_ID]: {
    crop: 'corn',
    type: 'soil_test',
    timing: 'preplant',
    input_id: DEFAULT_K_INPUT_ID,
    formula_name: POTASSIUM_ISU,
    minimum_rate: 50,
    expected_yield: 180,
  },
  [BPH_SIKORA_ID]: {
    crop: 'corn',
    type: 'soil_test',
    timing: 'preplant',
    input_id: DEFAULT_PH_INPUT_ID,
    formula_name: LIME_ISU_69,
    minimum_rate: 50,
    expected_yield: 180,
  },
};

export const DEFAULT_RX_VALUES_BR = {
  [PHOSPHORUS_ID]: {
    crop: 'corn',
    type: 'soil_test',
    timing: 'preplant',
    input_id: DEFAULT_P_INPUT_ID,
    formula_name: PHOSPHORUS_BRAZIL,
    minimum_rate: 50,
    expected_yield: 180,
  },
  [POTASSIUM_ID]: {
    crop: 'corn',
    type: 'soil_test',
    timing: 'preplant',
    input_id: DEFAULT_K_INPUT_ID,
    formula_name: POTASSIUM_BRAZIL,
    minimum_rate: 0,
    expected_yield: 180,
    maximum_rate: 50,
  },
  [BPH_SIKORA_ID]: {
    crop: 'corn',
    type: 'soil_test',
    timing: 'preplant',
    input_id: CUSTOM_RNP_BRAZIL,
    formula_name: SATURATION_BRAZIL,
    minimum_rate: 50,
    expected_yield: 180,
  },
};

export const formulaDisplayNames = {
  [PHOSPHORUS_ISU as string]: 'isuPhosphorus',
  [LIME_ISU_60 as string]: 'isuLimeTo6',
  [LIME_ISU_65 as string]: 'isuLimeTo65',
  [LIME_ISU_69 as string]: 'isuLimeTo69',
  [POTASSIUM_ISU as string]: 'isuPotassium',
  [PHOSPHORUS_TRI_STATE as string]: 'triStatePhosphorus',
  [POTASSIUM_TRI_STATE as string]: 'triStatePotassium',
  [LIME_TRI_STATE_60 as string]: 'triStateLimeTo6',
  [LIME_TRI_STATE_65 as string]: 'triStateLimeTo65',
  [LIME_TRI_STATE_68 as string]: 'triStateLimeTo68',
  [LIME_TRI_STATE_OH_60 as string]: 'triStateOhLimeTo6',
  [LIME_TRI_STATE_OH_65 as string]: 'triStateOhLimeTo65',
  [LIME_TRI_STATE_OH_68 as string]: 'triStateOhLimeTo68',
  [PHOSPHORUS_SDSU_MN_BRAY as string]: 'sdsuPhosphorusBray',
  [PHOSPHORUS_SDSU_MN_OLSEN as string]: 'sdsuPhosphorusOlsen',
  [POTASSIUM_SDSU_MN as string]: 'sdsuPotassium',
  [LIME_SDSU as string]: 'sdsuLime',
  [SATURATION_BRAZIL as string]: 'brazilSaturationMethod',
  [PHOSPHORUS_BRAZIL as string]: 'brazilPhosphorus',
  [POTASSIUM_BRAZIL as string]: 'brazilPotassium',
};

export const PERCENT = 'percent';
export const LBS_PER_TON = 'lbs_per_ton';

export const rxUnitToSymbol = {
  [PERCENT as string]: '%',
  [LBS_PER_TON as string]: 'lbs/ton',
};

export const BRAZIL_ONLY_INPUTS = [SUPER_FOSFATO_SIMPLES];
