import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typeahead } from 'common/Components/Mantine/Typeahead';
import { Button, Modal, Stack, Text, Checkbox, Space, Center, Loader, Flex } from '@mantine/core';
import setToast from 'actions/toastActions';
import { useTranslation, getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { sortByValue } from 'util/sortByName';
import { RootState } from 'store';
import { getPatternSamplers } from 'store/user/thunks';
import { putSamplePlan } from 'store/samplePlans/requests';
import { getStateGeometry } from 'store/states/thunks';
import { SamplePlanTrackingType, SamplePlanType } from 'store/samplePlans/types';
import { US } from 'constants/countries';
import FieldInState from 'common/FieldInState';
import { getFieldGeometry } from 'store/fields/thunks';
import { FiSearch } from 'react-icons/fi';

import styles from './AssignSampler.module.css';
import { getSamplerOptions, getScannerOptions } from 'util/samplePlan';
import { userFullName } from 'util/userRoles';

type AssignSamplerPropsType = {
  samplePlan: SamplePlanType | SamplePlanTrackingType;
  fieldId: number;
  operationId: number;
  onClose: () => void;
  onSubmit?: () => void;
  getFieldsWithUpdates: () => void;
  billing_user_id: number | null;
  opened: boolean;
};

const AssignSampler = ({
  samplePlan,
  fieldId,
  operationId,
  onClose,
  onSubmit,
  getFieldsWithUpdates,
  billing_user_id,
  opened,
}: AssignSamplerPropsType) => {
  const dispatch = useDispatch();
  const language = useBroswerLanguage();
  const isProOrTillRx = samplePlan.is_pro || samplePlan.is_till_rx;

  const [userSelected, setUserSelected] = useState<number | null>(null);
  const [scannerSelected, setScannerSelected] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);
  const [assignToUnassignedPlans, toggleAssignToUnassigned] = useState(false);
  const [isSubmitting, toggleIsSubmitting] = useState(false);
  const [viewUser, setViewUser] = useState<string>('');
  const [viewScanner, setViewScanner] = useState<string>('');

  const fieldGeometry = useSelector((state: RootState) => state.fieldGeometry.geometries[fieldId]);
  const properties = fieldGeometry?.features[0].properties || {};

  const planCheck = samplePlan as SamplePlanTrackingType;
  const canSample = Boolean(
    planCheck?.nutrient_ordered_count ||
      planCheck?.performance_ordered_count ||
      planCheck?.pressure_ordered_count ||
      samplePlan?.products.length,
  );

  const { samplers, hasFailed, stateGeometry, isFetchingState, operation } = useSelector(
    (state: RootState) => {
      return {
        samplers: state.user.patternSamplers,
        hasFailed: state.user.hasFailed,
        operation: state.operations.operationsById[Number(operationId)],
        isFetchingState: state.stateGeometry.isFetchingList.includes(properties?.state),
        stateGeometry:
          state.stateGeometry.geometries[properties.country_code || US]?.[properties.state],
      };
    },
  );

  useEffect(() => {
    if (!samplers && !hasFailed) {
      dispatch(getPatternSamplers());
    }
  }, [samplers, hasFailed]);

  useEffect(() => {
    if (!stateGeometry && !hasFailed && properties?.state) {
      dispatch(getStateGeometry(properties.state, properties.country_code || US));
    }
  }, [stateGeometry, fieldGeometry, hasFailed]);

  const onlySamplers = sortByValue(
    (samplers || []).map((user) => ({
      id: user.id,
      value: user.id,
      label: userFullName(user),
      can_scan: user.can_scan,
    })),
    'label',
  );

  const options = [
    {
      id: -1,
      value: '-1',
      label: 'None',
      can_scan: true,
    },
    ...getSamplerOptions(operation).filter((sampler) => sampler.id > 0),
    ...onlySamplers,
  ];

  const scannerOptions = [
    {
      id: -1,
      value: '-1',
      label: 'None',
    },
    ...getScannerOptions(operation).filter((scanner) => scanner.id > 0),
    ...onlySamplers.filter((user) => user.can_scan),
  ];

  const submit = async () => {
    toggleIsSubmitting(true);
    const body = {
      assigned_to_id: userSelected && userSelected > 0 ? userSelected : null,
      assigned_to_scan_id: scannerSelected && scannerSelected > 0 ? scannerSelected : null,
      field_id: samplePlan.field_id,
      assign_to_unassigned_plans: assignToUnassignedPlans,
    };
    try {
      await putSamplePlan(body, samplePlan.id);
    } catch (error) {
      dispatch(setToast(getString('assignSamplerError', language), 'error', 7000));
    }
    toggleIsSubmitting(false);
    onSubmit && onSubmit();
    onClose();
    dispatch(getFieldGeometry(fieldId));
    if (assignToUnassignedPlans) {
      getFieldsWithUpdates();
    }
  };

  useEffect(() => {
    if (options.length > 1 && loading) {
      if (samplePlan?.assigned_to_id && !userSelected) {
        const selected = options.find((option) => option.id === samplePlan?.assigned_to_id);
        if (selected && Number(selected?.value) > -1) {
          handleUserSelection(Number(selected.value), false);
          handleTextChangeUser(selected.label);
        }
        setLoading(false);
      }
      if (samplePlan?.assigned_to_scan_id && !scannerSelected) {
        const selected = scannerOptions.find(
          (option) => option.id === samplePlan?.assigned_to_scan_id,
        );
        if (selected && Number(selected?.value) > -1) {
          handleScannerSelection(Number(selected.value), false);
          handleTextChangeScanner(selected.label);
        }
        setLoading(false);
      }
      if (!samplePlan?.assigned_to_id && !samplePlan?.assigned_to_scan_id) {
        setLoading(false);
      }
    }
  }, [samplePlan, options, scannerOptions, userSelected, loading, scannerSelected]);

  const handleUserSelection = (val: number | string, setScanner = true) => {
    const sampler = options.find((option) => option.id === Number(val));
    setUserSelected(Number(val));
    setViewUser(sampler?.label || '');
    if (sampler?.can_scan && setScanner && !scannerSelected && isProOrTillRx) {
      setScannerSelected(Number(val));
      setViewScanner(sampler?.label || '');
    }
  };

  const handleTextChangeUser = (value: string) => {
    setViewUser(value);
  };

  const handleScannerSelection = (val: number | string, setSampler = true) => {
    const scanner = scannerOptions.find((option) => option.id === Number(val))?.label || '';
    setScannerSelected(Number(val));
    setViewScanner(scanner);
    if (setSampler && !userSelected && canSample) {
      setUserSelected(Number(val));
      setViewUser(scanner);
    }
  };

  const handleTextChangeScanner = (value: string) => {
    setViewScanner(value);
  };

  const removeFilterSampler = () => {
    setUserSelected(null);
    setViewUser('');
  };

  const removeFilterScanner = () => {
    setScannerSelected(null);
    setViewScanner('');
  };

  const getAssignSamplerBody = () => {
    if (isFetchingState || isSubmitting || !fieldGeometry || loading) {
      return (
        <Center h="25rem">
          <Loader />
        </Center>
      );
    }
    if (!billing_user_id && !samplePlan.rnd) {
      return (
        <div className={styles.AssignBillingResponsibility}>
          *{getString('assignSamplerNoBilling', language)}
        </div>
      );
    }

    return (
      <>
        <Text>{getString('assignSamplerPar', language)}:</Text>
        {canSample && (
          <Flex justify="space-between" w="80%">
            <Text>{getString('soilCollection', language)}</Text>
            <Typeahead
              onTextChange={handleTextChangeUser}
              onSelect={handleUserSelection}
              onDeselect={removeFilterSampler}
              data={options}
              data-test-id="assign-sampler-search-bar"
              placeholder={getString('searchSampler', language)}
              leftSection={<FiSearch />}
              value={viewUser}
              w="20rem"
            />
          </Flex>
        )}
        {isProOrTillRx && (
          <Flex justify="space-between" w="80%">
            <Text>{getString('soilScan', language)}</Text>
            <Typeahead
              onTextChange={handleTextChangeScanner}
              onSelect={handleScannerSelection}
              onDeselect={removeFilterScanner}
              data={scannerOptions}
              data-test-id="assign-scanner-search-bar"
              placeholder={getString('searchSampler', language)}
              leftSection={<FiSearch />}
              value={viewScanner}
              w="20rem"
            />
          </Flex>
        )}
        <Checkbox
          checked={assignToUnassignedPlans}
          onChange={() => toggleAssignToUnassigned(!assignToUnassignedPlans)}
          label={getString('assignAllPlans', language)}
          className={styles.Checkbox}
        />
        <Button data-test-id="invite-sampler" onClick={submit}>
          {getString('inviteSampler', language)}
        </Button>
        <Space h="md" />
      </>
    );
  };

  return (
    <Modal opened={opened} onClose={onClose} size="lg">
      <Stack justify="center" align="center">
        <Text size="xl" fw={700}>
          {useTranslation('assignSampler')}
        </Text>
        {stateGeometry?.type && (
          <>
            <Text>
              {getString('state', language)}: {stateGeometry.features[0].properties?.name}
            </Text>
            <FieldInState field={fieldGeometry} stateGeometry={stateGeometry} />
          </>
        )}
        {getAssignSamplerBody()}
      </Stack>
    </Modal>
  );
};

export default AssignSampler;
