import React, { useState } from 'react';
import { Box, Grid, Image, Loader, Space, Stack, Text } from '@mantine/core';
import styles from './Container.module.css';
import { AnalyticType } from 'store/analytics/types';
import { FieldType, SamplingPlanType } from 'store/fields/types';
import { NOT_ANALYZED, NO_DATA } from 'constants/fieldRisks';
import { calibratedAnalyticIds, OM_ID, OM_MEASURED_ID } from 'constants/results';
import { getAnalyticLength, getCropImage } from 'util/overviewResultsDisplay';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import ChartModal from './ChartModal';
import FieldRow from './FieldRow';

interface PropTypes {
  cropType: string;
  subCategoryAnalyticsFiltered: { [key: string]: AnalyticType[] };
  filteredFields: FieldType[];
  samplingPlans: SamplingPlanType[];
  hasFailedList: number[];
  willFetchList: number[];
  isFetchingList: number[];
  cropRef: any;
  isLoading: boolean;
  isPrinting: boolean;
}

interface ModalState {
  field: FieldType;
  analytic: AnalyticType;
  samplingPlan: SamplingPlanType;
}

const CropFieldRows = ({
  cropType,
  subCategoryAnalyticsFiltered,
  filteredFields,
  samplingPlans,
  isFetchingList,
  hasFailedList,
  willFetchList,
  cropRef,
  isLoading,
  isPrinting,
}: PropTypes) => {
  const language = useBroswerLanguage();
  const [modalOpen, toggleModalOpen] = useState(false);
  const [modalDisplayData, setModalDisplayData] = useState<ModalState | null>(null);

  const openChartModal = (
    field: FieldType,
    analytic: AnalyticType | null,
    samplingPlan: SamplingPlanType | undefined,
    risk: string,
    isFoliarWithResults: boolean | undefined,
  ) => {
    if (!samplingPlan || !analytic) return;
    if (samplingPlan.analytics[analytic.category][analytic.id]) {
      if (![NOT_ANALYZED, NO_DATA].includes(risk) || isFoliarWithResults) {
        setModalDisplayData({
          field,
          analytic,
          samplingPlan,
        });
        toggleModalOpen(true);
      }
    } else if (analytic.id === OM_ID && samplingPlan.analytics[analytic.category][OM_MEASURED_ID]) {
      setModalDisplayData({
        field,
        analytic,
        samplingPlan,
      });
      toggleModalOpen(true);
    }
  };

  const analyticLength = getAnalyticLength(subCategoryAnalyticsFiltered);
  const sortedAnalytics = subCategoryAnalyticsFiltered[cropType];

  return (
    <>
      {modalDisplayData && (
        <ChartModal
          hideModal={() => toggleModalOpen(false)}
          activeAnalytic={modalDisplayData?.analytic}
          samplingPlan={modalDisplayData?.samplingPlan}
          field={modalDisplayData?.field}
          opened={modalOpen}
        />
      )}
      {!!filteredFields.length && (
        <Box className={styles.WrapCropFields}>
          <Grid gutter="xs" columns={analyticLength} className={styles.StickyHeader}>
            <Grid.Col span={1} className={styles.CropIconWrapper}>
              <Image
                id={`crop-icon-${cropType}`}
                h={40}
                w="auto"
                className={styles.CropIcon}
                src={getCropImage(cropType)}
              />
            </Grid.Col>
            <Grid.Col span={1} className={styles.CropIconWrapper}>
              <Stack align="center" justify="flex-end">
                {isLoading && <Loader size="xs" />}
                <Space h="xs" />
              </Stack>
            </Grid.Col>
            <Grid.Col span={1} className={styles.HeaderColWrapper}>
              <Text size="xs" fw={700} span>
                {getString('crop', language)}
              </Text>
            </Grid.Col>
            <Grid.Col span={1} className={styles.HeaderColWrapper}>
              <Text size="xs" fw={700} span>
                {getString('targetYield', language)}
              </Text>
            </Grid.Col>
            {sortedAnalytics
              .filter((single) => !calibratedAnalyticIds.includes(single.id)) // Only display SDS, Shotgun or QPCR
              .map((analytic) => (
                <Grid.Col span={1} className={styles.HeaderColWrapper} key={analytic.id}>
                  <Text size="xs" fw={700} span>
                    {analytic.name}
                  </Text>
                </Grid.Col>
              ))}
          </Grid>
          <Space h="xs" />
          {filteredFields.map((field, idx) => (
            <FieldRow
              key={field.features[0].properties.id}
              cropRef={idx === 0 ? cropRef.targetRef : null}
              cropType={cropType}
              categorySubs={sortedAnalytics}
              field={field.features[0].properties}
              fieldGeometry={filteredFields.find(
                (filtField) =>
                  field.features[0].properties.id === filtField.features[0].properties.id,
              )}
              samplingPlans={samplingPlans.filter(
                (plan) => plan.field_id === field.features[0].properties.id,
              )}
              openChartModal={openChartModal}
              hasFailedGeometry={hasFailedList.includes(field.features[0].properties.id)}
              isFetchingGeometry={isFetchingList.includes(field.features[0].properties.id)}
              willFetch={willFetchList.includes(field.features[0].properties.id)}
              analyticLength={analyticLength}
              isPrinting={isPrinting}
            />
          ))}
        </Box>
      )}
    </>
  );
};

export default CropFieldRows;
