import React from 'react';
import classNames from 'classnames';
import { SamplingPlanType } from 'store/fields/types';
import { productAbbrNames } from 'constants/products';
import { COMPLETED } from 'constants/samplePlanning';
import { isProductComplete, getProductList } from 'util/product';
import styles from './Status.module.css';

type StatusPropsType = {
  samplingPlan: SamplingPlanType;
};

const Status = ({ samplingPlan }: StatusPropsType) => {
  const generateStatusBubble = (product: string) => (
    <div
      className={classNames(styles.Bubble, {
        [styles.FilledBubble]:
          samplingPlan?.sampling_status === COMPLETED || isProductComplete(samplingPlan, product),
      })}
      key={product}
    >
      <span className={styles.BubbleText}>{productAbbrNames[product]}</span>
    </div>
  );

  return (
    <div className={styles.Wrapper}>
      {getProductList(
        samplingPlan.products,
        samplingPlan.is_pro,
        samplingPlan.is_till_rx,
        samplingPlan.is_590_analysis,
      ).map((product) => generateStatusBubble(product))}
    </div>
  );
};

export default Status;
