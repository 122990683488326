import React from 'react';
import { Stack, Text, Select, Flex, Switch, Group, Checkbox, Textarea } from '@mantine/core';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { FieldType } from 'store/fields/types';
import {
  getSamplePlanProductsBio,
  getSamplePlanProductsNutrient,
  getSelectedBioProduct,
  getSelectedNutrientProduct,
  isNutrientPanel,
  getSelectedBioProductFromAnalysis,
} from 'util/product';
import { ZoneAnalysisKeyType } from 'store/zoneAnalysisV2/types';
import {
  COMPLETE_BIO,
  CURRENT_PRODUCTS,
  NITRATE_PANEL,
  NUTRIENT_PANEL,
  PRESSURE_NUTRIENT,
} from 'constants/products';
import useSampleTiming from 'util/hooks/useSampleTiming';
import { OperationType } from 'store/operation/types';
import { getSamplerOptions, getScannerOptions, getNewAnalysisFromUpdate } from 'util/samplePlan';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { updateZoneAnalysis } from 'store/zoneAnalysisV2/actions';
import { FONT_WEIGHT_BOLD } from 'constants/mantine';
import {
  DEFAULT_PRO_BIO_DENSITY,
  DEFAULT_PRO_NUTRIENT_DENSITY,
  ZONE_BY_ZONE,
  is590Capable,
} from 'constants/samplePlanning';
import { NUTRIENT } from 'constants/analysis';
import { BR } from 'constants/countries';

interface AnalysisFormProps {
  fieldGeometry: FieldType;
  operation: OperationType;
}

const AnalysisForm = ({ fieldGeometry, operation }: AnalysisFormProps) => {
  const language = useBroswerLanguage();
  const { timings } = useSampleTiming();
  const dispatch = useDispatch();

  const analysis = useSelector((state: RootState) => state.zoneAnalysisV2.plan);

  const isRestricted = Boolean(fieldGeometry?.features[0].properties.county?.restricted);
  const isBrazil = fieldGeometry?.features[0].properties.country_code === BR;
  const possibleProductsBio = getSamplePlanProductsBio(language, isRestricted);
  const selectedBioProduct = getSelectedBioProduct(analysis, language, isRestricted);
  const possibleProductsNutrient = getSamplePlanProductsNutrient(language, isRestricted);
  const selectedNutrientProduct = getSelectedNutrientProduct(analysis, language, isRestricted);
  const samplerOptions = getSamplerOptions(operation);
  const scannerOptions = getScannerOptions(operation);
  const isProScanOrTillRx = analysis.isProScan || analysis.isTillRx;

  const updatePlanState = (metaKeyValue: ZoneAnalysisKeyType) =>
    dispatch(updateZoneAnalysis(metaKeyValue));

  const handleNutrientSelection = (value: string | null) => {
    const isNutrientEnabled = value === NUTRIENT_PANEL;

    const newProducts =
      value === PRESSURE_NUTRIENT
        ? analysis.products.filter((p) => ![NUTRIENT_PANEL, NITRATE_PANEL].includes(p))
        : [...analysis.products, NUTRIENT_PANEL];
    updatePlanState({
      zones: analysis.isProScan && isNutrientPanel(newProducts) ? null : analysis.zones,
      products: newProducts,
      isProScan: Boolean(isNutrientEnabled && analysis.isProScan),
    });
  };

  const setupProScan = async () => {
    const isProScan = !analysis.isProScan;

    const newAnalysis = {
      ...analysis,
      isProScan,
      analysisMode: ZONE_BY_ZONE,
      isSplitDensityNitrate: false,
      scanDensity: DEFAULT_PRO_NUTRIENT_DENSITY,
      density:
        isProScan && !analysis.zones?.features.length ? DEFAULT_PRO_BIO_DENSITY : analysis.density,
    };

    updatePlanState(getNewAnalysisFromUpdate(newAnalysis, fieldGeometry));
  };

  const setup590Analysis = async () => {
    const is590Analysis = !analysis.is590Analysis;
    const newAnalysis = {
      ...analysis,
      is590Analysis,
    };
    updatePlanState(getNewAnalysisFromUpdate(newAnalysis, fieldGeometry));
  };

  const handleBioSelection = (product: string | null) => {
    if (product) {
      const { panels } = CURRENT_PRODUCTS[product];
      const isNutrientEnabled = panels.includes(NUTRIENT);
      const newAnalysis = {
        ...analysis,
        products: panels,
        isProScan: Boolean(isNutrientEnabled && analysis.isProScan),
      };
      updatePlanState(getNewAnalysisFromUpdate(newAnalysis, fieldGeometry));
    }
  };

  const updateNitratePanel = () => {
    if (analysis.products.includes(NITRATE_PANEL)) {
      const newProducts = analysis.products.filter((product) => product !== NITRATE_PANEL);
      updatePlanState({
        products: newProducts,
      });
    } else {
      updatePlanState({
        products: [...analysis.products, NITRATE_PANEL],
      });
    }
  };

  const updateTillageRx = () => {
    const newAnalysis = {
      ...analysis,
      isTillRx: !analysis.isTillRx,
    };
    updatePlanState(getNewAnalysisFromUpdate(newAnalysis, fieldGeometry));
  };

  const isCompleteBio = analysis.analysisMode === COMPLETE_BIO;
  const isNitratePanel = analysis.products.includes(NITRATE_PANEL);

  return (
    <Stack p="2rem" gap="sm">
      <Text size="xl" fw={FONT_WEIGHT_BOLD} td="underline">
        {getString('analysis', language)}
      </Text>
      <Text fw={700}>{getString('biological_analysis', language)}</Text>
      <Select
        data={possibleProductsBio}
        onChange={handleBioSelection}
        value={getSelectedBioProductFromAnalysis(analysis, language, isRestricted)?.value}
        w="18rem"
      />
      <Text fs="italic" size="sm">
        {selectedBioProduct?.msg}
      </Text>
      <Text fw={600}>{getString('nutrient_analysis', language)}</Text>
      <Flex align="center" justify="space-between" w="40rem">
        <Select
          data={possibleProductsNutrient}
          onChange={handleNutrientSelection}
          value={analysis.products.includes(NUTRIENT_PANEL) ? NUTRIENT_PANEL : PRESSURE_NUTRIENT}
          w="18rem"
        />
        {fieldGeometry.features[0].properties.country_code !== BR && (
          <Checkbox
            checked={analysis.isProScan}
            onChange={setupProScan}
            disabled={!analysis.products.includes(NUTRIENT_PANEL)}
            label={getString('pro10Res', language)}
            labelPosition="left"
          />
        )}
      </Flex>
      <Text fs="italic" size="sm">
        {selectedNutrientProduct?.msg}
      </Text>
      {is590Capable(
        fieldGeometry.features[0].properties.country_code || '',
        fieldGeometry.features[0].properties.state,
      ) && analysis.products.includes(NUTRIENT_PANEL) ? (
        <Flex justify="space-between" w="40rem">
          <Text w="35rem">
            <Text fw={FONT_WEIGHT_BOLD} span>
              {getString('analysis590Title', language)}:
            </Text>{' '}
            {getString('analysis590Description', language)}
          </Text>
          <Checkbox checked={analysis.is590Analysis} onChange={setup590Analysis} />
        </Flex>
      ) : null}
      <Flex direction="column">
        <Text c={isCompleteBio ? '#B7BBBF' : undefined} fw={FONT_WEIGHT_BOLD}>
          {getString('nitrateAnalysis', language)}
        </Text>
        <Text c={isCompleteBio ? '#B7BBBF' : undefined} fs="italic" size="xs">
          {getString('nitrateAnalysisMsg', language)}
        </Text>
      </Flex>
      <Switch
        checked={isNitratePanel}
        data-test-id="nitrate-toggle-slider"
        disabled={!analysis.products.includes(NUTRIENT_PANEL)}
        onChange={updateNitratePanel}
        size="lg"
      />
      {!isBrazil && (
        <>
          <Text fw={FONT_WEIGHT_BOLD}>{getString('tillMapper', language)}</Text>
          <Switch
            checked={analysis.isTillRx}
            data-test-id="tillrx-toggle-slider"
            onChange={updateTillageRx}
            size="lg"
          />
        </>
      )}
      <Text size="xl" fw={FONT_WEIGHT_BOLD} td="underline">
        {getString('sampling', language)}
      </Text>
      {analysis.products.length ? (
        <Group justify="space-between" w="25rem">
          <Text fw={600}>{getString('sampledBy', language)}:</Text>
          {operation.billing_user_id ? (
            <Select
              data-test-id="sampled-by"
              value={samplerOptions[analysis.samplerIndex]?.value}
              onChange={(value) =>
                value &&
                updatePlanState({
                  samplerIndex: samplerOptions?.findIndex((sam) => sam.value === value),
                })
              }
              data={samplerOptions}
            />
          ) : (
            <Text color="darkRed">**{getString('addBillingResponsibility', language)}**</Text>
          )}
        </Group>
      ) : null}
      {isProScanOrTillRx && (
        <Group justify="space-between" w="25rem">
          <Text fw={600}>{getString('scannedBy', language)}:</Text>
          <Select
            data-test-id="sampled-by"
            value={scannerOptions[analysis.scannerIndex]?.value}
            onChange={(value) => {
              value &&
                updatePlanState({
                  scannerIndex: scannerOptions?.findIndex((sam) => sam.value === value),
                });
            }}
            data={scannerOptions}
          />
        </Group>
      )}
      <Group justify="space-between" w="25rem">
        <Text fw={600}>{getString('sampleTiming', language)}:</Text>
        <Select
          data-test-id="sample-timing"
          value={timings[analysis.sampleTimingIndex]?.value}
          onChange={(val) =>
            val &&
            updatePlanState({
              sampleTimingIndex: timings.findIndex((timing) => timing.value === val),
            })
          }
          data={timings.map((timing) => ({
            value: timing.value,
            label: timing.displayName,
          }))}
          required
        />
      </Group>
      <Text fw={600}>{getString('samplerInstructions', language)}:</Text>
      <Textarea
        data-test-id="sampler-instructions"
        w="25rem"
        minRows={3}
        onChange={(e) => updatePlanState({ notes: e.target.value })}
        value={analysis?.notes}
      />
    </Stack>
  );
};

export default AnalysisForm;
