import {
  AA_ANALYSIS,
  CHEMISTRY,
  DNA_EXTRACTION,
  HOMOGENIZATION,
  NITRATE_ANALYSIS,
  OM_ANALYSIS,
  M3_ANALYSIS,
  NORMALIZED_DNA,
  PH_ANALYSIS,
  QPCR as QPCR_PLATE,
  SHOTGUN as SHOTGUN_PLATE,
} from './plates';

export const QPCR = 'qpcr';
export const SHOTGUN = 'shotgun';
export const SHOTGUN_QPCR = 'shotgun_qpcr';
export const GENOMIC = 'genomic';
export const NUTRIENT = 'nutrient';
export const M3 = 'm3';
export const PH = 'ph';
export const KCL = 'kcl';
export const NITRATE = 'nitrate';
export const AMMONIUM_ACETATE = 'ammonium_acetate';
export const MANUAL_RECORD = 'manual_record';
export const MODIFIED_MEHLICH = 'modified_mehlich';
export const SIKORA = 'sikora';
export const BRAY = 'bray';
export const OLSEN = 'olsen';
export const DTPA = 'dtpa';
export const LOSS_ON_IGNITION = 'loss_on_ignition';
export const OM = 'om';
export const AMPLICON = 'amplicon';

export const M3_RERUN = 'm3_rerun';
export const PH_RERUN = 'ph_rerun';
export const NITRATE_RERUN = 'nitrate_rerun';
export const AA_RERUN = 'aa_rerun';
export const OM_RERUN = 'om_rerun';
export const P_BRAY = 'p_bray';
export const K_BRAY = 'k_bray';

export const fullAnalysisOrderedList = [
  GENOMIC,
  QPCR,
  SHOTGUN_QPCR,
  SHOTGUN,
  NUTRIENT,
  M3,
  P_BRAY,
  K_BRAY,
  PH,
  KCL,
  NITRATE,
  AMMONIUM_ACETATE,
  LOSS_ON_IGNITION,
  OM,
  M3_RERUN,
  PH_RERUN,
  NITRATE_RERUN,
  AA_RERUN,
  OM_RERUN,
];

export const productAnalysisList = [SHOTGUN, QPCR, NUTRIENT, NITRATE];

export const chemistryAnalysisList = [M3, PH];
export const chemistryAnalysisListExpanded = [M3, PH, NITRATE, AMMONIUM_ACETATE, OM];
export const chemistry590List = [P_BRAY, K_BRAY];
export const chemistryExtractionList = [
  M3,
  SIKORA,
  MODIFIED_MEHLICH,
  KCL,
  AMMONIUM_ACETATE,
  LOSS_ON_IGNITION,
];
export const genomicAnalysisList = [SHOTGUN_QPCR, SHOTGUN, QPCR, AMPLICON];
export const rerunAnalysisList = [M3_RERUN, PH_RERUN, NITRATE_RERUN, AA_RERUN, OM_RERUN];

export const analysisToPlateTypes = {
  [GENOMIC as string]: [SHOTGUN_PLATE, QPCR_PLATE, NORMALIZED_DNA, DNA_EXTRACTION, HOMOGENIZATION],
  [QPCR as string]: [QPCR_PLATE, HOMOGENIZATION],
  [SHOTGUN as string]: [SHOTGUN_PLATE, HOMOGENIZATION],
  [SHOTGUN_QPCR as string]: [SHOTGUN_PLATE, QPCR_PLATE, HOMOGENIZATION],
  [NUTRIENT as string]: [AA_ANALYSIS, M3_ANALYSIS, PH_ANALYSIS, NITRATE_ANALYSIS, LOSS_ON_IGNITION],
  [M3 as string]: [M3_ANALYSIS],
  [PH as string]: [PH_ANALYSIS],
  [MODIFIED_MEHLICH as string]: [PH_ANALYSIS],
  [SIKORA as string]: [PH_ANALYSIS],
  [KCL as string]: [NITRATE_ANALYSIS],
  [NITRATE as string]: [NITRATE_ANALYSIS],
  [AMMONIUM_ACETATE as string]: [AA_ANALYSIS],
  [OM as string]: [OM_ANALYSIS],
  [LOSS_ON_IGNITION as string]: [OM_ANALYSIS],
};

export const plateTypesToAnalysis = {
  [HOMOGENIZATION as string]: genomicAnalysisList,
  [CHEMISTRY as string]: chemistryAnalysisListExpanded,
  [PH_ANALYSIS as string]: [SIKORA, MODIFIED_MEHLICH],
  [NITRATE_ANALYSIS as string]: [KCL],
  [M3_ANALYSIS as string]: [M3],
  [AA_ANALYSIS as string]: [AMMONIUM_ACETATE],
  [OM_ANALYSIS as string]: [OM],
};

export const analysisToRerunTypes = {
  [M3 as string]: [M3_RERUN, AA_RERUN],
  [PH as string]: [PH_RERUN, AA_RERUN],
  [KCL as string]: [NITRATE_RERUN],
  [OM as string]: [OM_RERUN],
};

export const analysisDisplayNames = {
  [GENOMIC as string]: 'genomic',
  [QPCR as string]: 'qPCR',
  [SHOTGUN as string]: 'shotgun',
  [SHOTGUN_QPCR as string]: 'shotgunQpcr',
  [NUTRIENT as string]: 'nutrient',
  [M3 as string]: 'M3',
  [PH as string]: 'pH',
  [KCL as string]: 'KCL',
  [NITRATE as string]: 'nitrate',
  [AMMONIUM_ACETATE as string]: 'ammoniumAcetate',
  [MANUAL_RECORD as string]: 'manualRecord',
  [MODIFIED_MEHLICH as string]: 'modifiedMehlich',
  [SIKORA as string]: 'sikora',
  [BRAY as string]: 'bray',
  [OLSEN as string]: 'olsen',
  [DTPA as string]: 'DTPA',
  [OM as string]: 'OM',
  [LOSS_ON_IGNITION as string]: 'lossOnIgnition',
  [M3_RERUN as string]: 'm3Rerun',
  [PH_RERUN as string]: 'phRerun',
  [NITRATE_RERUN as string]: 'kclRerun',
  [AA_RERUN as string]: 'aaRerun',
  [OM_RERUN as string]: 'omRerun',
  [AMPLICON as string]: 'amplicon',
};

export const analysisShortNames = {
  ...analysisDisplayNames,
  [AMMONIUM_ACETATE as string]: 'AA',
  [MANUAL_RECORD as string]: 'manual',
  [LOSS_ON_IGNITION as string]: 'LOI',
  [MODIFIED_MEHLICH as string]: 'mehlich',
  [P_BRAY as string]: 'Bray-1 P',
  [K_BRAY as string]: 'Bray-1 K',
};

export const labIcon = {
  [QPCR as string]: 'Q',
  [SHOTGUN as string]: 'S',
  [SHOTGUN_QPCR as string]: 'QS',
  [NUTRIENT as string]: 'N',
  [NITRATE as string]: 'N',
};

export const PENDING = 'pending';
export const SUCCESSFUL = 'successful';
export const FAILED = 'failed';
export const PENDING_M3_FAILED = 'm3_failed';
export const PENDING_PH_FAILED = 'ph_failed';
export const PLATE_LANES = [1, 2, 3, 4];
