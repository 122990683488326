import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typeahead } from 'common/Components/Mantine/Typeahead';
import { Modal, Stack, Text, Button, Space, Group, SegmentedControl } from '@mantine/core';
import setToast from 'actions/toastActions';
import { useTranslation, getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { capitalizeEveryWord } from 'util/stringUtils';
import sortByName from 'util/sortByName';
import { RootState } from 'store';
import { getPatternSamplers } from 'store/user/thunks';
import { requestPutBulkAssignPlans } from 'store/samplePlans/requests';
import { SamplePlanTrackingType, SamplePlanType } from 'store/samplePlans/types';
import { FiSearch } from 'react-icons/fi';
import { SAMPLE, SAMPLE_SCAN } from 'constants/samplePlanning';

type BulkAssignSamplerPropsType = {
  samplePlans: SamplePlanTrackingType[];
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

type OptionType = {
  name: string;
  label: string;
  id: number | null;
  value: number | null;
  can_scan: boolean;
};

export const BulkAssignSampler = ({
  samplePlans,
  open,
  onClose,
  onSubmit,
}: BulkAssignSamplerPropsType) => {
  const dispatch = useDispatch();
  const language = useBroswerLanguage();
  const [selectedView, setSelectedView] = useState(SAMPLE);
  const [userSelected, setUserSelected] = useState<OptionType | undefined>(undefined);
  const [scannerSelected, setScannerSelected] = useState<OptionType | undefined>(undefined);
  const [isSubmitting, toggleIsSubmitting] = useState(false);
  const [viewUser, setViewUser] = useState<string>('');
  const [viewScanner, setViewScanner] = useState<string>('');
  const [bulkSampleTypes, setBulkSampleTypes] = useState<any[]>([
    {
      id: 1,
      label: getString('sampleOnly', language),
      value: SAMPLE,
    },
    {
      id: 2,
      label: getString('samplePlusScan', language),
      value: SAMPLE_SCAN,
    },
  ]);
  const [completedViews, setCompletedViews] = useState<string[]>([]);

  const typeaheadRef = useRef<HTMLInputElement>(null);

  const { samplers } = useSelector((state: RootState) => ({
    samplers: state.user.patternSamplers,
  }));

  useEffect(() => {
    if (!samplers) {
      dispatch(getPatternSamplers());
    }
  }, [samplers]);

  const assignmentPlans = samplePlans.reduce(
    (acc, plan) => {
      if (plan.is_pro || plan.is_till_rx) {
        acc[SAMPLE_SCAN].push(plan);
      } else {
        acc[SAMPLE].push(plan);
      }
      return acc;
    },
    { [SAMPLE]: [], [SAMPLE_SCAN]: [] } as { [key: string]: SamplePlanTrackingType[] },
  );

  useEffect(() => {
    if (!assignmentPlans[SAMPLE].length) {
      setBulkSampleTypes(
        bulkSampleTypes.map((seg) => (seg.value === SAMPLE ? { ...seg, disabled: true } : seg)),
      );
      setSelectedView(SAMPLE_SCAN);
    }
    if (!assignmentPlans[SAMPLE_SCAN].length) {
      setBulkSampleTypes(
        bulkSampleTypes.map((seg) =>
          seg.value === SAMPLE_SCAN ? { ...seg, disabled: true } : seg,
        ),
      );
      setSelectedView(SAMPLE);
    }
  }, [assignmentPlans, samplePlans]);

  const plans = assignmentPlans[selectedView];

  const options = samplers
    ? sortByName(
        samplers.map((user) => {
          const displayName = capitalizeEveryWord(`${user.first_name} ${user.last_name}`);
          return {
            id: user.id,
            label: displayName,
            name: displayName,
            value: user.id,
            can_scan: user.can_scan,
          };
        }),
      )
    : [];

  const submit = async () => {
    toggleIsSubmitting(true);
    const body = {
      user_id: userSelected?.id || null,
      scanner_id: scannerSelected?.id || null,
      plan_ids: plans.map((plan: SamplePlanType | SamplePlanTrackingType) => plan.id),
    };
    try {
      await requestPutBulkAssignPlans(body);
      dispatch(setToast(getString('assignSamplersSuccessMsg', language)));
      const tempCompleted = [...completedViews, selectedView];
      setCompletedViews(tempCompleted);
      const isOnlySampleOrScan =
        !assignmentPlans[SAMPLE].length || !assignmentPlans[SAMPLE_SCAN].length;
      if (tempCompleted.length === 2 || (isOnlySampleOrScan && tempCompleted.length)) {
        // Only close if both views have been completed or if there is only one view
        onSubmit();
        onClose();
      }
      updateSampleView(selectedView === SAMPLE ? SAMPLE_SCAN : SAMPLE);
    } catch (error) {
      dispatch(setToast(getString('assignSamplerError', language), 'error', 7000));
    } finally {
      toggleIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (typeaheadRef.current) {
      typeaheadRef.current.focus();
    }
  }, [typeaheadRef]);

  const handleUserSelection = (val: number | string) => {
    const sampler = options.find((option) => option.id === Number(val));
    setViewUser(sampler?.label || '');
    setUserSelected(sampler);
  };

  const handleScannerSelection = (val: number | string) => {
    const sampler = options.find((option) => option.id === Number(val));
    setViewScanner(sampler?.label || '');
    setScannerSelected(sampler);
  };

  const removeFilter = () => {
    setUserSelected(undefined);
    setViewUser('');
  };

  const removeScannerFilter = () => {
    setScannerSelected(undefined);
    setViewScanner('');
  };

  const handleTextChangeUser = (value: string) => {
    setViewUser(value);
  };

  const handleTextChangeScanner = (value: string) => {
    setViewScanner(value);
  };

  const updateSampleView = (val: string) => {
    removeFilter();
    removeScannerFilter();
    setSelectedView(val);
  };

  const isProOrTillRx = plans.some((plan) => plan.is_pro || plan.is_till_rx);

  return (
    <Modal opened={open} onClose={onClose} size="lg" centered>
      <Stack justify="center" align="center">
        <Text size="xl" fw={700}>
          {useTranslation('assignSampler')}
        </Text>
        <SegmentedControl
          value={selectedView}
          data={bulkSampleTypes}
          onChange={(val) => updateSampleView(val)}
        />
        <Text>{`${getString('samplePlans', language)}: ${plans.length}`}</Text>
        <Text>{`${getString('acres', language)}: ${plans
          .reduce((total_acres, plan) => total_acres + plan.calculated_area_acres, 0)
          .toFixed(2)}`}</Text>
        <Text>{`${getString('samples', language)}: ${plans.reduce(
          (total_samples, plan) => total_samples + plan.sample_total_active_count,
          0,
        )}`}</Text>
        <Group justify="space-between" w="80%">
          <Text>{getString('soilCollection', language)}</Text>
          <Typeahead
            onTextChange={handleTextChangeUser}
            onSelect={handleUserSelection}
            onDeselect={removeFilter}
            data={options}
            data-test-id="assign-sampler-search-bar"
            placeholder={getString('searchSampler', language)}
            leftSection={<FiSearch />}
            value={viewUser}
            w="20rem"
          />
        </Group>
        {selectedView === SAMPLE_SCAN && (
          <Group justify="space-between" w="80%">
            <Text>{getString('soilScan', language)}</Text>
            <Typeahead
              onTextChange={handleTextChangeScanner}
              onSelect={handleScannerSelection}
              onDeselect={removeScannerFilter}
              data={options.filter((option) => option.can_scan)}
              data-test-id="assign-scanner-search-bar"
              placeholder={getString('searchSampler', language)}
              leftSection={<FiSearch />}
              value={viewScanner}
              w="20rem"
            />
          </Group>
        )}
        <Button
          onClick={submit}
          disabled={
            (isProOrTillRx ? !userSelected?.id && !scannerSelected?.id : !userSelected?.id) ||
            isSubmitting
          }
        >
          {getString('inviteSampler', language)}
        </Button>
      </Stack>
      <Space h="md" />
    </Modal>
  );
};
