import React from 'react';
import { getString } from 'strings/translation';
import {
  AWAITING_RESULTS,
  HIGH_RISK,
  LOW_RISK,
  MODERATE_RISK,
  NO_DATA,
  NOT_ANALYZED,
  AWAITING_RESULTS_LANG,
  NOT_ANALYZED_LANG,
  NO_DATA_LANG,
} from 'constants/fieldRisks';
import { capitalizeEveryWord } from 'util/stringUtils';
import {
  NORTHERN_CORN_ROOTWORM_ID,
  PATHOGENS,
  ROOTWORM_ID,
  SCN_ID,
  SOIL_ATTRIBUTES,
  WESTERN_CORN_ROOTWORM_ID,
  BCSR,
  bufferPhIds,
} from 'constants/results';
import { CANCELLED, WONT_SAMPLE } from 'constants/samples';
import { getRootwormRiskText } from 'util/fieldRisk';
import { getPlanAnalytic, getRiskDisplayName } from 'util/results';
import { SampleType, MapboxSample } from 'store/fields/types';
import { AnalyticType } from 'store/analytics/types';
import { roundThreeDecimal } from 'util/numUtils';
import styles from './MapPopup.module.css';

const createPopup = (
  clickedSample: MapboxSample,
  activeAnalytic: AnalyticType,
  midwestBenchmarkRiskSummary: string,
  language: string,
) => {
  const getRisk = (sample: SampleType) => {
    if (sample.status === CANCELLED || sample.status === WONT_SAMPLE) {
      return {
        risk: NOT_ANALYZED,
        riskText: getString(NOT_ANALYZED_LANG, language),
        quantity: null,
        unit: null,
      };
    }
    if (activeAnalytic.id === ROOTWORM_ID) {
      const ncrw = sample.analytics[activeAnalytic.category]?.[NORTHERN_CORN_ROOTWORM_ID];
      const wcrw = sample.analytics[activeAnalytic.category]?.[WESTERN_CORN_ROOTWORM_ID];
      if (!ncrw.hasOwnProperty('quantity') && !wcrw.hasOwnProperty('quantity')) {
        return {
          risk: AWAITING_RESULTS,
          riskText: getString(AWAITING_RESULTS_LANG, language),
          quantity: null,
          unit: null,
        };
      }
      if (ncrw.quantity === null && wcrw.quantity === null) {
        return {
          risk: NO_DATA,
          riskText: getString(NO_DATA_LANG, language),
          quantity: null,
          unit: null,
        };
      }

      const combinedEggs = (ncrw.quantity || 0) + (wcrw.quantity || 0);
      const commonData = {
        quantity: combinedEggs,
        unit: 'eggs',
      };
      if (combinedEggs > 6) {
        return {
          ...commonData,
          risk: HIGH_RISK,
          riskText: getString('highRisk', language),
        };
      }
      if (combinedEggs > 0) {
        return {
          ...commonData,
          risk: MODERATE_RISK,
          riskText: getString('moderateRisk', language),
        };
      }
      return {
        ...commonData,
        risk: LOW_RISK,
        riskText: getString('lowRisk', language),
      };
    }

    const analyticValue = getPlanAnalytic(sample, activeAnalytic?.category, activeAnalytic?.id);
    // Checking if key exists. OK even if value is None
    if (!analyticValue || !analyticValue.hasOwnProperty('risk_level')) {
      return {
        risk: AWAITING_RESULTS,
        riskText: getString(AWAITING_RESULTS_LANG, language),
        quantity: null,
        unit: null,
      };
    }

    switch (analyticValue.risk_level) {
      case HIGH_RISK:
        return {
          risk: HIGH_RISK,
          riskText: getString('highRisk', language),
          quantity: analyticValue.quantity,
          unit: analyticValue.unit,
        };
      case MODERATE_RISK:
        return {
          risk: MODERATE_RISK,
          riskText: getString('moderateRisk', language),
          quantity: analyticValue.quantity,
          unit: analyticValue.unit,
        };
      case LOW_RISK:
        return {
          risk: LOW_RISK,
          riskText: getString('lowRisk', language),
          quantity: analyticValue.quantity,
          unit: analyticValue.unit,
        };
      default:
        return {
          risk: NO_DATA,
          riskText: getString(NO_DATA_LANG, language),
          quantity: analyticValue.quantity || 0,
          unit: analyticValue.unit || '',
        };
    }
  };

  const riskValues = getRisk(clickedSample.properties);

  const summary = (() => {
    const { analytics, status } = clickedSample.properties;

    if (riskValues.risk === AWAITING_RESULTS) {
      return (
        <div>
          <b>{getString('sampleRisk', language)}</b>
          {`:  N/A (${getString(AWAITING_RESULTS_LANG, language)})`}
        </div>
      );
    }
    if (riskValues.risk === NOT_ANALYZED) {
      return (
        <div>
          <b>{getString('sampleRisk', language)}</b>
          {`:  N/A (${capitalizeEveryWord(status)})`}
        </div>
      );
    }
    if (riskValues.risk === NO_DATA) {
      return (
        <div>
          <b>{getString('sampleRisk', language)}</b>
          {`: N/A (${getString('insufficientDataFound', language)})`}
        </div>
      );
    }

    if (activeAnalytic.id === ROOTWORM_ID) {
      const ncrw = analytics[PATHOGENS][NORTHERN_CORN_ROOTWORM_ID];
      const wcrw = analytics[PATHOGENS][WESTERN_CORN_ROOTWORM_ID];
      return (
        <div>
          <div>
            <b>{getString('sampleRisk', language)}</b>
            {`: ${riskValues.riskText} (${riskValues.quantity || 0} ${riskValues.unit})`}
          </div>
          <div>
            <b>NCRW</b>
            {`: ${ncrw?.quantity || 0} ${ncrw?.unit}`}
          </div>
          <div>
            <b>WCRW</b>
            {`: ${wcrw?.quantity || 0} ${wcrw?.unit}`}
          </div>
          <div>{getRootwormRiskText(riskValues.risk, false, language)}</div>
        </div>
      );
    }
    if (activeAnalytic.id === SCN_ID) {
      const scn = analytics[PATHOGENS][SCN_ID];
      return (
        <div>
          <div>
            <b>{getString('sampleRisk', language)}</b>
            {`: ${riskValues.riskText} (${riskValues.quantity || 0} ${riskValues.unit})`}
          </div>
          <div>
            {`${scn?.risk_summary.moderate} ${getString(
              'perSampleIndicates',
              language,
            )} ${getString('moderateRisk', language)}. ${scn?.risk_summary.high} ${getString(
              'perSampleIndicates',
              language,
            )} ${getString('highRisk', language)}.`}
          </div>
        </div>
      );
    }
    if (activeAnalytic.category === SOIL_ATTRIBUTES) {
      const value = analytics[SOIL_ATTRIBUTES][activeAnalytic.id];
      const level = getRiskDisplayName(activeAnalytic.id, riskValues.risk, language);
      const quantity = riskValues.quantity
        ? ` (${roundThreeDecimal(riskValues.quantity)} ${riskValues.unit})`
        : '';
      const optimal = bufferPhIds.includes(activeAnalytic.id)
        ? ''
        : `${getString('optimal', language)} ${activeAnalytic.name}: ${
            value?.risk_summary?.low || getString(AWAITING_RESULTS_LANG, language)
          }`;
      return (
        <div>
          <div>
            <b>{getString('sampleRisk', language)}</b>
            {`: ${level}${quantity}`}
          </div>
          <div>{optimal}</div>
        </div>
      );
    }

    const value = analytics[activeAnalytic.category][activeAnalytic.id];
    const unit = riskValues.unit ? ` ${riskValues.unit}` : '';
    const moderateSummary = `(${value?.risk_summary.moderate}) ${getString(
      'levelsIndicateModerateRisk',
      language,
    )}.`;
    const highStringKey =
      activeAnalytic.category === BCSR ? 'levelsIndicateHighRisk' : 'levelsIndicateYieldLoss';
    const highRiskSummary = ` (${value?.risk_summary.high}) ${getString(highStringKey, language)}.`;
    return (
      <div>
        <div>
          <b>{getString('sampleRisk', language)}</b>
          {`: ${riskValues.riskText} (${roundThreeDecimal(riskValues.quantity)}${unit})`}
        </div>
        {value?.benchmark_set && (
          <div>
            <b>{getString('benchmark', language)}</b>
            {`: ${value.benchmark_set}`}
          </div>
        )}
        {value?.region && (
          <div>
            <b>{getString('region', language)}</b>
            {`: ${value.region}`}
          </div>
        )}
        <div>{`${value?.risk_summary.moderate ? moderateSummary : ''}${value?.risk_summary.high ? highRiskSummary : ''}`}</div>
      </div>
    );
  })();
  return (
    <div className={styles.Popup}>
      <div>
        <b>{getString('sample', language)}</b>
        {`: ${clickedSample.properties.id}`}
      </div>
      {summary}
      {midwestBenchmarkRiskSummary && (
        <div className={styles.TooltipTextItalic}>{midwestBenchmarkRiskSummary}</div>
      )}
    </div>
  );
};

export default createPopup;
