import { CRW, RKN } from 'constants/samplePlanning';
import { User } from 'store/user/types';
import { FeatureCollection, Point, Polygon } from '@turf/helpers';
import { POINT, POLYGON } from 'constants/mapbox';
import {
  ANALYSIS_TYPES,
  CLEAR_ZONE_ANALYSIS_V2,
  UPDATE_ZONE_ANALYSIS_V2,
  ZoneAnalysisActionsType,
} from './types';
import { SamplePlanType } from '../samplePlans/types';
import { getDefaultAnalysis } from 'util/samplePlan';
import { SampleGeoJSON } from 'util/generalTypes';

export type ZoneAnalysisStateType = {
  plan: {
    scannerIndex: number;
    existingSamples: SampleGeoJSON[];
    tempPlan: Partial<SamplePlanType> | null;
    isSplitDensity: boolean;
    is590Analysis: boolean;
    isProScan: boolean;
    isTillRx: boolean;
    rnd: boolean;
    name: string;
    readyToSample: boolean;
    samplerIndex: number;
    sampleTimingIndex: number;
    products: string[];
    analysisMode: string;
    gridAngle: number;
    disableMapTools: boolean;
    enableButtonSpinner: boolean;
    operationUsers: User[];
    priceSummary: SamplePlanType['price_summary'];
    isOrderButtonDisabled: boolean;
    zones: FeatureCollection<Polygon | Point> | null;
    previewZones: FeatureCollection<Polygon | Point> | null;
    zoneGeomType: typeof POLYGON | typeof POINT;
    zonesLocked: boolean;
    editScanPoints: boolean;
    isConfirmationVisible: boolean;
    existingPlan: SamplePlanType | null;
    splitIndex: number;
    isSplitDensityNitrate: boolean;
    notes: string | undefined;
    pressureVersion: typeof CRW | typeof RKN;
    pressurePlus: boolean;
    creationOption: string;
    proPointCreationOption: string;
    density: number;
    accordionLocation: string;
    scanDensity: number;
    agencyId: number | undefined | null;
    zoneCreationType:
      | typeof ANALYSIS_TYPES.CREATION_OPTION
      | typeof ANALYSIS_TYPES.PRO_POINT_CREATION_OPTION;
  };
};

export const initialState: ZoneAnalysisStateType = {
  plan: getDefaultAnalysis(),
};

export const ZoneAnalysisReducer = (
  state = initialState,
  action: ZoneAnalysisActionsType,
): ZoneAnalysisStateType => {
  switch (action.type) {
    case UPDATE_ZONE_ANALYSIS_V2:
      return {
        ...state,
        plan: {
          ...(state.plan || {}),
          ...action.payload.metaKeyValues,
        },
      };
    case CLEAR_ZONE_ANALYSIS_V2:
      return {
        ...state,
        plan: initialState.plan,
      };
    default:
      return state;
  }
};
